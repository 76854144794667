import React, { useState, ReactNode, } from 'react';

import './Collapse.scss';

type Props = {
    children: ReactNode[] | ReactNode,
    collapsed?: boolean,
    showLabel?: any,
    hideLabel?: any,
}

const Collapse = ({ children, collapsed = true, showLabel = 'Show', hideLabel = 'Hide' }: Props) => {
    const [isCollapsed, setIsCollapsed] = useState(collapsed);

    return (
        <>
            <div
                className='collapse-button'
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                {isCollapsed ? showLabel : hideLabel}
        </div>
            <div
                className={`collapse-content ${isCollapsed ? 'collapsed' : 'expanded'}`}
                aria-expanded={isCollapsed}
            >
                {children}
            </div>
        </>
    );
};

export default Collapse;